import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  BaseChartDirective,
  provideCharts,
  withDefaultRegisterables,
} from 'ng2-charts';
import { EMPTY, Observable } from 'rxjs';
import { UsageRecordDto, UsageRecordViewModel } from '../dto';

type GroupBy = 'day' | 'month';
@Component({
  selector: 'gentext-usage-graph',
  imports: [CommonModule, FormsModule, BaseChartDirective],
  providers: [provideCharts(withDefaultRegisterables())],
  templateUrl: './usage-graph.component.html',
  styleUrls: ['./usage-graph.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UsageGraphComponent implements OnInit {
  @Input()
  usageRecordGet: () => Observable<Array<UsageRecordDto>> = () => EMPTY;

  @Input()
  apiBaseUrl = '';
  usageRecords: UsageRecordViewModel[] = [];
  groupBy: GroupBy = 'day';
  chartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Number of words',
      },
    ],
    labels: [],
  };
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0.2,
        borderWidth: 2,
      },
    },
    scales: {
      yAxis: {
        type: 'linear',
        position: 'left',
        title: {
          text: 'Number of words',
          display: true,
          align: 'center',
        },
      },
    },
    plugins: {
      legend: { display: true },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
      },
    },
  };

  loading = false;
  hasData = false;

  ngOnInit() {
    this.getUsage();
  }
  groupByChange() {
    this.setDataForGraph(this.usageRecords);
  }
  private getUsage() {
    this.loading = true;
    this.usageRecordGet().subscribe((usage) => {
      const viewModels = usage.map((u) => u as UsageRecordViewModel);
      viewModels.forEach((u) => (u.dateTimeDate = new Date(u.dateTime)));
      this.setDataForGraph(viewModels);
    });
  }

  private setDataForGraph(usage: UsageRecordViewModel[]) {
    this.usageRecords = usage;
    const grouped: UsageRecordViewModel[] = [];

    usage.forEach((u, i) => {
      const prevDateString = i > 0 ? usage[i - 1].dateTime : undefined;
      const prevDate = prevDateString ? new Date(prevDateString) : undefined;
      const groupByFunction = (date: Date) => {
        return this.groupBy === 'day' ? date.getDate() : date.getMonth();
      };

      if (
        prevDate &&
        groupByFunction(prevDate) === groupByFunction(u.dateTimeDate)
      ) {
        grouped[grouped.length - 1].words += u.words;
      } else {
        const formatString =
          this.groupBy === 'day' ? 'MMM d, yyyy' : 'MMM yyyy';
        u.label = format(u.dateTimeDate, formatString);
        grouped.push({ ...u });
      }
    });

    this.loading = false;
    this.hasData = grouped.length > 0;
    this.chartData = {
      datasets: [
        {
          data: grouped.map((u) => u.words),
          label: 'Number of Words',
          yAxisID: 'yAxis',
          type: grouped.length > 1 ? 'line' : 'bar',
        },
      ],
      labels: grouped.map((u) => u.label),
    };
  }
}
