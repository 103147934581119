import { Inject, Injectable } from '@angular/core';
import {
  ApplicationInsights,
  IConfig,
  IConfiguration,
  IExceptionTelemetry,
  ITraceTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import {
  LOGGING_CONFIGURATION,
  LoggingConfiguration,
} from './logging.configuration';

@Injectable()
export class LoggingService {
  private appInsights?: ApplicationInsights;

  trace(trace: ITraceTelemetry) {
    this.logToConsole(trace);
    this.appInsights?.trackTrace(trace);
  }

  private logToConsole(trace: ITraceTelemetry) {
    if (!this.loggingConfiguration.consoleEnabled) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    let consoleFn: (message?: any, ...optionalParams: any) => void =
      console.log;
    switch (trace.severityLevel) {
      case SeverityLevel.Critical:
      case SeverityLevel.Error:
        consoleFn = console.error;
        break;
      case SeverityLevel.Warning:
        consoleFn = console.warn;
        break;
      case SeverityLevel.Verbose:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        consoleFn = () => {};
    }
    if (trace.properties != null) {
      consoleFn(trace.message, trace.properties);
    } else {
      consoleFn(trace.message);
    }
  }

  exception(exception: IExceptionTelemetry) {
    console.error({ exception });
    this.appInsights?.trackException(exception);
  }
  configure() {
    // Application Insights Configuration
    const configObj: IConfiguration & IConfig = {
      connectionString: process.env['NX_APP_INSIGHTS_CONNECTION_STRING'],
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      distributedTracingMode: 2,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    };

    try {
      this.appInsights = new ApplicationInsights({ config: configObj });
      this.appInsights.loadAppInsights();
    } catch {
      console.warn('Loading AppInsights failed');
    }
  }

  constructor(
    @Inject(LOGGING_CONFIGURATION)
    private loggingConfiguration: LoggingConfiguration,
  ) {}
}
