import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { LicenseDto } from '@gentext/api-client';
import { TranslocoModule } from '@jsverse/transloco';
import { BillingUiService } from '../billing-ui.service';

@Component({
  selector: 'gentext-alerts-banner',
  imports: [CommonModule, TranslocoModule, FontAwesomeModule],
  templateUrl: './alerts-banner.component.html',
  styleUrl: './alerts-banner.component.css',
})
export class AlertsBannerComponent {
  @Input() license: LicenseDto | undefined;
  hasError = false;
  get showFailedPaymentBanner() {
    return this.license?.hasPendingInvoice;
  }

  constructor(
    library: FaIconLibrary,
    private billingUiService: BillingUiService,
  ) {
    library.addIcons(faTriangleExclamation);
  }
  clickFailedPaymentBanner() {
    this.hasError = false;
    if (!this.license) {
      this.hasError = true;
      return;
    }
    this.billingUiService
      .managePlan(this.license)
      .catch(() => (this.hasError = true));
  }
}
