import { Component, NgZone, OnInit } from '@angular/core';
import {
  CheckoutRequestPlan,
  CreateCheckoutSessionRequestDto,
  LicenseDto,
  UsageRecordService,
} from '@gentext/api-client';
import { LoggingService } from '@gentext/logging';

import { BillingService } from '@gentext/api-client';
import { BillingUiService } from '../billing-ui.service';
import { UserService } from '../user.service';

@Component({
  selector: 'gentext-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: false,
})
export class HomeComponent implements OnInit {
  loading = false;
  hasError = false;
  license: LicenseDto | undefined;
  viewPlans = false;
  hoverPremium = false;
  hoverBusiness = false;
  isYearlyChecked = false;
  get apiBaseUrl() {
    return `https://${process.env['NX_API_HOST']}`;
  }

  get usageRecordGet() {
    return () => this.usageService.usageRecordGet();
  }

  get email() {
    return this.userService.userEmail;
  }
  CheckoutRequestPlan = CheckoutRequestPlan;
  get userIsAdmin() {
    return this.email?.toLocaleLowerCase().endsWith('@gentext.ai') ?? false;
  }

  get isFreePlan() {
    return this.license?.planId === 'free';
  }
  constructor(
    private billingService: BillingService,

    private userService: UserService,
    private logging: LoggingService,
    private billingUiService: BillingUiService,
    private usageService: UsageRecordService,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.getLicense();
  }
  getLicense() {
    this.loading = true;
    this.hasError = false;
    this.billingService
      .billingLicenseGet()

      .subscribe({
        next: (license: LicenseDto) => {
          this.license = license;
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (err: any) => {
          this.logging.exception(err);
          this.loading = false;
          this.hasError = true;
        },
      });
  }

  upgrade(plan: CheckoutRequestPlan) {
    if (this.license?.planId !== 'free' || this.license?.hasPendingInvoice) {
      this.manage();
      return;
    }
    const request: CreateCheckoutSessionRequestDto = {
      plan,
      webBaseUrl: `${window.location.origin}/confirmation`,
      customerId: this.license?.customerId,
    };
    const newWindow = window.open();
    if (newWindow === null) {
      this.hasError = true;
      return;
    }
    this.loading = true;
    this.billingService
      .billingCreateCheckoutSessionPost(request)

      .subscribe({
        next: (response) => {
          this.loading = false;
          newWindow.location = response.url;
        },
        error: (err) => {
          this.logging.exception(err);
          this.loading = false;
          this.hasError = true;
        },
      });
  }

  viewGenTextPlans() {
    this.viewPlans = !this.viewPlans;
  }

  manage() {
    this.hasError = false;
    if (!this.license) {
      this.hasError = true;
      return;
    }
    this.billingUiService.managePlan(this.license).catch(() => {
      this.hasError = true;
    });
  }
  cancelSubscription() {
    this.zone.runOutsideAngular(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)['churnkey'].init('show', {
        subscriptionId: this.license?.subscriptionId,
        customerId: this.license?.customerId,
        authHash: this.license?.churnkeyHash,
        appId: 'uc4sq28sy',
        mode: process.env['NX_ENVIRONMENT'] === 'prod' ? 'live' : 'test',
        provider: 'stripe',
        record: true,
      });
    });
  }
}
