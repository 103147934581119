export * from './admin.service';
import { AdminService } from './admin.service';
export * from './billing.service';
import { BillingService } from './billing.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './featureFlag.service';
import { FeatureFlagService } from './featureFlag.service';
export * from './paper.service';
import { PaperService } from './paper.service';
export * from './preferences.service';
import { PreferencesService } from './preferences.service';
export * from './reference.service';
import { ReferenceService } from './reference.service';
export * from './usageRecord.service';
import { UsageRecordService } from './usageRecord.service';
export const APIS = [AdminService, BillingService, DocumentService, FeatureFlagService, PaperService, PreferencesService, ReferenceService, UsageRecordService];
